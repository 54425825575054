<template>
  <section class="rank-top-wrap">
    <el-row :gutter="10">
      <el-col :sm="8" :md="8">
        <div class="rank-item rank-item2">
          <div class="sort-number" style="color: rgb(225, 148, 73)">2</div>
          <div class="rank-icon">
            <svg-icon iconClass="jiangbei2"></svg-icon>
          </div>
          <div class="rank-item-name">{{rankData[1] ? rankData[1].ownerName : emprtyName }}</div>
          <div class="rank-item-count">
            <span class="count-text">{{rankData[1] ? `${rankData[1].signedCount} 个` : emprtyCount }}</span>
          </div>
          
        </div>
      </el-col>
      <el-col :sm="8" :md="8">
        <div class="rank-item">
          <div class="sort-number" style="color: rgb(253, 220, 58)">1</div>
          <div class="rank-icon">
            <svg-icon iconClass="jiangbei1"></svg-icon>
          </div>
          <div class="rank-item-name">{{rankData[0] ? rankData[0].ownerName : emprtyName }}</div>
          <div class="rank-item-count">
            <span class="count-text">{{rankData[0] ? `${rankData[0].signedCount} 个` : emprtyCount }}</span>
          </div>
          
        </div>
      </el-col>
      <el-col :sm="8" :md="8">
        <div class="rank-item rank-item3">
          <div class="sort-number" style="color: rgb(211, 211, 211)">3</div>
          <div class="rank-icon">
            <svg-icon iconClass="jiangbei3"></svg-icon>
          </div>
          <div class="rank-item-name">{{rankData[2] ? rankData[2].ownerName : emprtyName }}</div>
          <div class="rank-item-count">
            <span class="count-text">{{rankData[2] ? `${rankData[2].signedCount} 个` : emprtyCount }}</span>
          </div>
          
        </div>
      </el-col>
    </el-row>
    <!-- <div class="no-data" v-if="rankData.length <= 0">暂无数据</div> -->
  </section>
</template>

<script>
export default {
  props: {
    rankData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      emprtyName: "虚位以待",
      emprtyCount: "暂无数量"
    };
  },
};
</script>

<style lang="scss" scoped>
.rank-top-wrap {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  .rank-item {
    text-align: center;
    padding-bottom: .7em;
    .rank-icon {
      font-size: 5.7em;
      border-top: 4px solid rgb(253, 220, 58);
      padding: .2em;
      max-width: 100px;
      margin: 0 auto;
    }
    .rank-item-name {
      font-size: 1.7em;
      font-weight: bolder;
    }
    .rank-item-count {
      box-sizing: border-box;
      font-size: 1.4em;
      color: #fff;
      margin-top: 1em;
      .count-text {
        background-color: rgb(253, 220, 58);
        padding: 2px .7em;
        border-radius: 4px;
        padding: 2px .7em;
        border-radius: 4px;
      }
    }
    .sort-number {
      box-sizing: border-box;
      font-size: 2.8em;
      font-weight: 800;
    }
    &.rank-item2 {
      padding-top: 2.8em;
      .rank-icon {
        border-top: 4px solid rgb(225, 148, 73);
      }
      .count-text {
        background-color: rgb(225, 148, 73);
      }
    }
    &.rank-item3 {
      padding-top: 4.3em;
      .rank-icon {
        border-top: 4px solid rgb(211, 211, 211);
      }
      .count-text {
        background-color: rgb(211, 211, 211);
      }
    }
  }
  .no-data {
    box-sizing: border-box;
    padding: 1.1em 0;
    text-align: center;
    color: #989898;
    font-size: 1.1em;
  }
}
</style>