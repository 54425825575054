<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <section class="top3-rank-wrap base-shadow">
      <div class="rank-list-wrap-head" style="margin-bottom: 10px">
        <span class="title">排行榜前三</span>
      </div>
      <RankTop3 :rankData="top3RankList" v-loading="loading" />
    </section>
    <section class="rank-list-wrap base-shadow">
      <div class="rank-list-wrap-head">
        <span class="title">所有排行榜</span>
      </div>
      <RankList :rankData="rankList" v-loading="loading" />
    </section>
    <el-backtop target=".base-page">
      <i class="el-icon-upload2"></i>
    </el-backtop>
  </section>
</template>

<script>
import RankTop3 from "./components/RankTop3";
import RankList from "./components/RankList";
import FilterSection from "./components/FilterSection";
import { GetStatisticsSignRangking } from "@/views/statisticAnalysis/api";
import { ShowApiError } from "@/request/error";
import PageHeader from "@/components/PageHeader";
export default {
  components: {
    RankTop3,
    RankList,
    FilterSection,
    PageHeader
  },
  data() {
    return {
      loading: false,
      params: {},
      top3RankList: [],
      rankList: [],
    };
  },
  created() {
    this.getRankList();
  },
  methods: {
    getRankList() {
      this.loading = true;
      GetStatisticsSignRangking(this.params)
        .then((res) => {
          this.rankList = res.data;
          // 截取前三名
          this.top3RankList = this.rankList.length > 0 ? this.rankList.slice(0.3) : [];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("拉取排行榜数据异常", err);
        });
    },
    onRefresh() {
      this.getRankList();
    },
    onFilterChange(data) {
      this.params = data;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
$baseMargin: 20px;
.rank-list-wrap-head {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  .title {
    font-size: 15px;
    color: #000;
    font-weight: bolder;
  }
}
.top3-rank-wrap {
  box-sizing: border-box;
  margin: $baseMargin;
  padding: 15px;
  background-image: repeating-linear-gradient(
      135deg,
      rgba(86, 86, 86, 0.02) 0px,
      rgba(86, 86, 86, 0.02) 22px,
      rgba(202, 202, 202, 0.02) 22px,
      rgba(202, 202, 202, 0.02) 67px,
      rgba(247, 247, 247, 0.02) 67px,
      rgba(247, 247, 247, 0.02) 113px,
      rgba(135, 135, 135, 0.02) 113px,
      rgba(135, 135, 135, 0.02) 132px,
      rgba(157, 157, 157, 0.02) 132px,
      rgba(157, 157, 157, 0.02) 153px,
      rgba(53, 53, 53, 0.02) 153px,
      rgba(53, 53, 53, 0.02) 171px,
      rgba(17, 17, 17, 0.02) 171px,
      rgba(17, 17, 17, 0.02) 181px,
      rgba(179, 179, 179, 0.02) 181px,
      rgba(179, 179, 179, 0.02) 220px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(58, 58, 58, 0.02) 0px,
      rgba(58, 58, 58, 0.02) 41px,
      rgba(198, 198, 198, 0.02) 41px,
      rgba(198, 198, 198, 0.02) 60px,
      rgba(176, 176, 176, 0.02) 60px,
      rgba(176, 176, 176, 0.02) 99px,
      rgba(173, 173, 173, 0.02) 99px,
      rgba(173, 173, 173, 0.02) 146px,
      rgba(164, 164, 164, 0.02) 146px,
      rgba(164, 164, 164, 0.02) 167px,
      rgba(179, 179, 179, 0.02) 167px,
      rgba(179, 179, 179, 0.02) 205px,
      rgba(228, 228, 228, 0.02) 205px,
      rgba(228, 228, 228, 0.02) 230px,
      rgba(23, 23, 23, 0.02) 230px,
      rgba(23, 23, 23, 0.02) 241px
    ),
    repeating-linear-gradient(
      135deg,
      rgba(190, 190, 190, 0.02) 0px,
      rgba(190, 190, 190, 0.02) 15px,
      rgba(74, 74, 74, 0.02) 15px,
      rgba(74, 74, 74, 0.02) 45px,
      rgba(98, 98, 98, 0.02) 45px,
      rgba(98, 98, 98, 0.02) 71px,
      rgba(43, 43, 43, 0.02) 71px,
      rgba(43, 43, 43, 0.02) 95px,
      rgba(131, 131, 131, 0.02) 95px,
      rgba(131, 131, 131, 0.02) 118px,
      rgba(21, 21, 21, 0.02) 118px,
      rgba(21, 21, 21, 0.02) 130px,
      rgba(77, 77, 77, 0.02) 130px,
      rgba(77, 77, 77, 0.02) 167px,
      rgba(231, 231, 231, 0.02) 167px,
      rgba(231, 231, 231, 0.02) 189px
    ),
    linear-gradient(90deg, rgb(251, 251, 251), rgb(250, 250, 250));
}
.rank-list-wrap {
  box-sizing: border-box;
  margin: $baseMargin;
  padding: 15px;
}
</style>